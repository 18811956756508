// External Dependencies
// import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Internal Dependencies
import reportWebVitals from "./reportWebVitals";

// Pages
import SurveyResults from "./pages/SurveyResults/";
import Login from "./pages/Login/";
import Dashboard from "./pages/Dashboard/";
import Profile from "./pages/Profile/";
import Earnings from "./pages/Earnings/";
import "./index.css";

// Components
import HamburgerMenu from "./components/HamburgerMenu/";
import Header from "./components/Header/";

const theme = createTheme({
  typography: {
    fontFamily: ["BlinkMacSystemFont", '"Segoe UI"'].join(","),
  },
  palette: {
    chart0: {
      main: "#000075",
    },
    chart1: {
      main: "#4876d2",
    },
    chart2: {
      main: "#0000A3",
    },
    chart3: {
      main: "#0e86d4",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

// An example of sub paths, this is new

root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <HamburgerMenu />
      {/* <Header /> TO DO: dynamically hide the header in login (current soln is not clean) */}
      <Routes>
        <Route index path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <>
              <Header />
              <Dashboard />
            </>
          }
        />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Earnings" element={<Earnings />} />
        <Route
          path="surveys"
          element={
            <>
              <Header />
              <SurveyResults />
            </>
          }
        >
          <Route
            path=":engagementId"
            element={
              <>
                <Header />
                <SurveyResults />
              </>
            }
          />
          <Route
            path="new"
            element={
              <>
                <Header />
                <SurveyResults />
              </>
            }
          />
          <Route
            index
            element={
              <>
                <Header />
                <SurveyResults />
              </>
            }
          />
        </Route>
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
