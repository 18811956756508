// External Dependencies
import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Container,
  Box,
  Grid,
  Paper,
  Typography,
  Avatar,
  CardHeader,
} from "@mui/material/";
import { styled } from "@mui/system";
// import { gql, useQuery } from "@apollo/client";

// Internal Dependencies
import { userData } from "./test-data.js";

const ButtonContainer = styled(Container)`
  display: flex;
  padding: 10px;
  margin: 10px;
  justify-content: space-between;
  width: 40%;
`;

const FilledButton = styled(Button)`
  border-radius: 50px;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 1.1px;
  white-space: no-wrap;
`;

const SeeThroughButton = styled(Button)`
  border-radius: 50px;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 1.1px;
  border: 2px solid;
`;

const Headline = styled(Typography)`
  margin: 20px;
  padding: 20px;
  font-weight: 300;
  letter-spacing: 1.1px;
`;

const LabelStyle = styled(Typography)`
  color: grey;
  font-size: 12px;
  font-weight: 600;
  padding: 2px;
`;

const CustomGrid = styled(Grid)`
  width: 75%;
  padding: 20px;
`;

const Profile = (props) => {
  // TO DO: replace with DB query
  const {
    firstName,
    lastName,
    email,
    gender,
    yearOfBirth,
    speciality,
    yearsOfPractice,
    practiceSetting,
    location,
  } = userData;

  return (
    <Container>
      <Box mb={4}>
        <Paper elevation={3} mb={4}>
          <Box mb={2}>
            <Headline variant={"h4"}>My Profile</Headline>
          </Box>
          <div
            style={{
              margin: 10,
              display: "flex",
              height: "1px",
              backgroundColor: "grey",
            }}
          />

          <CardHeader
            avatar={
              <Avatar margin="10">
                {firstName[0]}
                {lastName[0]}
              </Avatar>
            }
            titleTypographyProps={{ variant: "h5" }}
            title={firstName.concat(" ", lastName)}
          />

          <div
            style={{
              margin: 10,
              display: "flex",
              height: "1px",
              backgroundColor: "grey",
            }}
          />
          <CustomGrid container>
            <Grid item xs={12} sm={6} md={4}>
              <LabelStyle>Email</LabelStyle>
              <Typography>{email}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LabelStyle>Gender</LabelStyle>
              <Typography>{gender}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LabelStyle>Year of Birth</LabelStyle>
              <Typography>{yearOfBirth}</Typography>
            </Grid>
          </CustomGrid>
          <div
            style={{
              width: "75%",
              margin: 10,
              flex: 1,
              height: "1px",
              backgroundColor: "grey",
            }}
          />
          <CustomGrid container>
            <Grid item xs={12} sm={6} md={4}>
              <LabelStyle>Speciality</LabelStyle>
              <Typography>{speciality}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LabelStyle>Years of Practice</LabelStyle>
              <Typography>{yearsOfPractice}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}></Grid>
          </CustomGrid>
          <div
            style={{
              width: "75%",
              margin: 10,
              flex: 1,
              height: "1px",
              backgroundColor: "grey",
            }}
          />
          <CustomGrid container>
            <Grid item xs={12} sm={6} md={4}>
              <LabelStyle>Practice Setting</LabelStyle>
              <Typography>{practiceSetting}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LabelStyle>Location</LabelStyle>
              <Typography>{location}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}></Grid>
          </CustomGrid>
        </Paper>
      </Box>
      <CustomGrid container>
        <Grid item xs={6} sm={4} md={4}>
          <FilledButton variant="contained">Back to Dashboard</FilledButton>
        </Grid>
        <Grid item xs={6} sm={4} md={4}>
          <SeeThroughButton variant="outlined">Sign Out</SeeThroughButton>
        </Grid>
      </CustomGrid>
    </Container>
  );
};

export default Profile;
