const lang = {
  "en-CA": {
    // General/root
    general  : {
      signIn: "Sign In.",
    },
    // Page specific
    signIn :{
      welcome:
      "Hey there, welcome to Key Ops' Insights Console. Please sign in.",
    }
  },
  "en-US": {
    // General/root
    signIn: "Sign In.",
    // Page specific
    signIn :{
      welcome:
      "Hey there, welcome to Key Ops' Insights Console. Please sign in.",
    }
  },
  "fr-CA": {
    // General/root
    signIn: "",
    // Page specific
    signIn :{
      welcome:
      "",
    }
  },
};

export default lang;
