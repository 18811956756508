// External Dependencies
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Button,
  Container,
  Box,
  SwipeableDrawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material/";
import {
  AttachMoney,
  AccountCircle,
  FeaturedPlayList,
  Feedback,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

// Internal Dependencies

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

// TODO needs code cleaning check and commenting
const ChartJS = ({ type, labels, data }) => {
  const theme = useTheme();

  const chartRef = useRef(null);


  useEffect(() => {
    const chartJSData = {
      labels,
      datasets: [
        {
          data,
          // TODO, look for a dynamic solution
          backgroundColor: [
            theme.palette.chart0.main,
            theme.palette.chart1.main,
            theme.palette.chart2.main,
            theme.palette.chart3.main,
          ],
          fill: false,
        },
      ],
    };

    const options = {
      title: {
        display: true,
        // text: "World population per region (in millions)",
      },
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false, //type !== "bar",
        },
      },
      scales: {
        x: {
          display: type === "bar",
          grid: {
            display: false,
          },
          ticks: {
            callback: (value, index, ticks) => {
              return type === "bar"
                ? String(labels[index]).substring(0, 15)
                : null;
            },
          },
        },
        y: {
          display: type === "bar",
          grid: {
            display: false,
          },
        },
      },
      responsive: true,
    };
    const myChart = new Chart(chartRef.current, {
      type,
      data: chartJSData,
      options,
    });
  }, []);
  return <canvas ref={chartRef}></canvas>;
};

export default ChartJS;
