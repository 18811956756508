// External Dependencies
import axios from "axios";

export const fetchData = async (
  publicRoute,
  method,
  endpoint,
  pathParam,
  queryStringParams,
  queryBody
) => {
  return new Promise(async (resolve, reject) => {
    let headers = { "Content-Type": "application/json" };
    // TODO, move this into a seperate repo, include all the utils
    if (!publicRoute) {
      try {
        const accessTokenResults = await axios({
          headers,
          baseURL: process.env.REACT_APP_API_URL,
          method: "get",
          url: "access-token",
          withCredentials: true,
        });

        headers["keyops__accesstokens"] = accessTokenResults.data.accessToken;
      } catch (e) {
        window.location.href = "/login";
        return;
      }
    }

    // 2. Build the URL
    const url = pathParam ? `/${endpoint}/${pathParam}` : `/${endpoint}`;

    // 3. Return the call
    let results;
    try {
      results = await axios({
        headers,
        baseURL: process.env.REACT_APP_API_URL,
        method,
        url,
        params: queryStringParams,
        data: queryBody,
        // This might need to change for private vs public
        withCredentials: true
      });
    } catch (e) {
      results = e;
    }
    return resolve(results);
  });
};
