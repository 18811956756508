export const userData = {
  firstName: "Mike",
  lastName: "Laine",
  email: "hello@mikelaine.com",
  gender: "M",
  yearOfBirth: 1993,
  speciality: "Clinical Immunology",
  yearsOfPractice: 5,
  practiceSetting: "Community Hospital",
  location: "Ontario",
};
