// External Dependencies
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Paper,
  Typography,
  Container,
  Grid,
  Avatar,
  CardHeader,
} from "@mui/material/";
import { styled } from "@mui/system";
import axios from "axios";
import CircleIcon from "@mui/icons-material/Circle";
import AssignmentIcon from "@mui/icons-material/Assignment";
// import {fetchData} from 'KeyOps-JavascriptUtils';

// Internal Dependencies
import SurveyCard from "../../components/SurveyCard/";
import { fetchData } from "../../utils/api";

// Styled Components
const SurveyCarsouel = styled("div")`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Headline = styled(Typography)`
  margin: 20px;
  padding: 0px 10px 0px 10px;
  font-weight: 300;
  letter-spacing: 1.1px;
`;

const CustomGrid = styled(Grid)`
  padding: 10px;
`;

const SeeThroughButton = styled(Button)`
  border-radius: 50px;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 1.1px;
  border: 2px solid;
`;

const CardContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`;

const Dashboard = (props) => {
  const [user, setUser] = React.useState([]);
  const [completedSurveys, setCompletedSurveys] = React.useState([]);
  const [inProgressdSurveys, setInProgressdSurveys] = React.useState([]);

  const getData = async () => {
    // 1. Get client info for display purposes
    const client = await fetchData(false, "get", "clients", {
      state: "closed",
    });
    setUser(client.data.data.user);

    // 2. Grab the completed surveys
    const completedResults = await fetchData(false, "get", "surveys", false, {
      state: "closed",
    });
    setCompletedSurveys(completedResults.data.data.surveys);

    // 3. Grab the in progress/publushed surveys
    const inProgressResults = await fetchData(false, "get", "surveys", false, {
      state: "published",
    });
    setInProgressdSurveys(inProgressResults.data.data.surveys);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Container maxWidth="xl">
      <Headline variant={"h3"}>
        <b>Welcome back {user.name}</b>
      </Headline>
      <Headline variant={"h5"}>
        You have {completedSurveys.length} complete engagements
      </Headline>
      <CustomGrid container>
        <>
          {completedSurveys.map(
            ({ id, title, engagementId, surveySparrowId }) => {
              return (
                <CustomGrid item xs={12} sm={12} md={6}>
                  <Paper>
                    <CardHeader
                      avatar={
                        <Avatar margin="10">
                          <AssignmentIcon />
                        </Avatar>
                      }
                      titleTypographyProps={{ variant: "h5" }}
                      title={
                        <CustomGrid container>
                          <CustomGrid item xs={12} sm={8}>
                            <Typography> {title} </Typography>
                          </CustomGrid>
                          <CustomGrid item xs={12} sm={4}>
                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/surveys/${engagementId}`}
                            >
                              <SeeThroughButton>View results</SeeThroughButton>
                            </Link>
                          </CustomGrid>
                        </CustomGrid>
                      }
                    />
                  </Paper>
                </CustomGrid>
              );
            }
          )}
        </>
      </CustomGrid>
      <div
        style={{
          margin: 5,
          display: "flex",
          height: "1px",
          backgroundColor: "grey",
        }}
      />
      <Headline variant={"h5"}>
        You have {inProgressdSurveys.length} engagements in progress
      </Headline>
      <CustomGrid container>
        <>
          {inProgressdSurveys.map(({ title, ...rest }) => {
            // console.log(rest)
            return (
              <CustomGrid item xs={12} sm={12} md={6}>
                <Paper>
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{ height: "10px", width: "10px" }}
                        margin="10"
                      >
                        <CircleIcon color="success" />
                      </Avatar>
                    }
                    titleTypographyProps={{ variant: "h5" }}
                    title={
                      <>
                        <Typography> {title} </Typography>
                      </>
                    }
                  />
                </Paper>
              </CustomGrid>
            );
          })}
        </>
      </CustomGrid>
    </Container>
  );
};

export default Dashboard;
